import chalk from 'chalk';
import stackTrace from 'stack-trace';

const LogLevel = {
	Info: 'INFO',
	Debug: 'DEBUG',
	Warn: 'WARN',
	Error: 'ERROR',
};

// h/t https://stackoverflow.com/a/15270931
const basename = (path) => path.split(/[\\/]/).pop();

export const isValidLogLevel = (level) =>
	Object.values(LogLevel).includes(level);

// For use by AuditLog in the backend
export const StackTraceUtil = stackTrace;

// "Random" max length of codeLocation string - trims long filenames
// const MAX_LENGTH = 22;

// safeLog protects against runtime problems in node 8.11
/* eslint-disable no-console */
const failedMethods = {};
const safeLog = (method, ...args) => {
	if (method === 'debug') {
		// eslint-disable-next-line no-param-reassign
		method = 'info'; // debug method does not exist in node 8.11
	}

	const fn = console[method];
	if (typeof fn === 'function') {
		fn.apply(console, args);
	} else {
		if (!failedMethods[method]) {
			failedMethods[method] = true;
			console.log('***** INTERNAL ERROR *****');
			console.log(
				`***** Logger tried to call method '${method}' on console, but that didn't exist, falling back to console.log...`,
			);
			console.log('***** INTERNAL ERROR *****');
		}

		console.log(...args);
	}
};

/* eslint-enable no-console */

class Logger {
	static setFilter(filterCallback) {
		this.filterCallback = filterCallback;
	}

	static log(levelInput, ...args) {
		if (args.length === 0) {
			// Basically, if someone calls Logger.debug() with no parameters,
			// they get a callback to output a debug message that accepts
			// only 3 args:
			// -message - the string to output
			// -context - Arbitrary object to output to the console (and log)
			// -customContextProps - Arbitrary object NOT to log to the console, but include in JSON/datadog logs
			const fn = (message, context, customContextProps) => {
				const currentProps = levelInput.type
					? levelInput
					: { type: levelInput };
				return this.log(
					{
						...currentProps,
						customContextProps: {
							...currentProps.customContextProps,
							...customContextProps,
						},
					},
					message,
					context,
				);
			};

			// User can also do Logger.info().log(...)
			fn.log = fn;
			return fn;
		}
		// eslint-disable-next-line no-console
		// console.log(`Logger.log debug:`, levelInput, ...args);

		const { filterCallback } = this;
		// Meta data to use for logging
		const { level, method, color, customPrefix, codeLocationOverride } =
			Logger.logLevelMeta(levelInput);

		// For use in threads, we intercept log statements and pass to parent
		// so this utility takes the __loggerMeta (built in child thread)
		// and logs that explicitly, then skips the rest of the logic below
		const lastArg = args ? args[args.length - 1] : null;
		if (lastArg && lastArg.__loggerMeta) {
			const { output } = lastArg.__loggerMeta;
			const [
				date,
				pid,
				location,
				// eslint-disable-next-line no-shadow
				customPrefix,
				// eslint-disable-next-line no-unused-vars
				unusedLevel,
				...outputArgs
			] = output;
			if (
				filterCallback &&
				!filterCallback(levelInput, location, pid, ...outputArgs)
			) {
				return null;
			}

			// We can't just pass .output to console like console.log(...output)
			// because the chalk colors don't come thru from the child thread.
			// Therefore, we have to explicitly extract the fields (above)
			// and re-apply chalk colors to match output from this thread
			safeLog(
				method,
				chalk.grey(date),
				chalk.grey(pid),
				chalk.cyan(location),
				chalk.magenta(customPrefix),
				`[${chalk[color](level)}]`,
				...outputArgs,
			);

			return null;
		}

		// Get location (outside of Logger) where the log originated
		let filepath;
		let line;
		let trace = stackTrace.get();
		if (!Array.isArray(trace) && typeof trace === 'string') {
			const list = trace.split('\n');
			const match = list.find(
				(test) => test.trim().startsWith('at') && !test.includes(__filename),
			);

			const idx1 = match.indexOf('(');
			const idx2 = match.indexOf(')');
			const fileAndLine = match.substring(idx1 + 1, idx2 - 1);
			[filepath, line] = fileAndLine.split(':');

			// console.log(`string trace debug:`, { match, idx1, idx2, fileAndLine });
		} else {
			const caller = trace.find(
				(data) => data && data.getFileName && data.getFileName() !== __filename,
			);
			filepath = caller.getFileName();
			line = caller.getLineNumber();
		}
		let file = basename(filepath || '');

		// index.js is not helpful, so replace with containing folder name
		if (file === 'index.js') {
			file = basename(filepath.replace(/\/index.js$/, ''));
		}

		// Build short string for file location and line number
		const codeLocation = codeLocationOverride || `${file}:${line}`; // + '@' + caller.getFunctionName(); // TBD do we need function name in logs?
		// const codeLocationShort =
		// 	(codeLocation.length > MAX_LENGTH ? '…' : '') +
		// 	codeLocation.substring(
		// 		codeLocation.length - MAX_LENGTH,
		// 		codeLocation.length,
		// 	);
		// .padStart(MAX_LENGTH, ' ');

		if (
			filterCallback &&
			!filterCallback(levelInput, codeLocation, process.pid, ...args)
		) {
			return null;
		}

		// Build output - in the future, could send to cloud or Sentry, etc
		const output = [
			chalk.grey(`(PID ${process.pid})`),
			chalk.grey(new Date().toISOString()),
			customPrefix ? chalk.magenta(customPrefix) : '',
			// Make all log-levels same string length for nice alignment with padEnd()
			// "[" + chalk[color](level.padEnd(DEBUG.length, ' ')) + "]",
			`[${chalk[color](level)}]`,
			chalk.grey(`${codeLocation}:`),
			// // Actual user's log statement
			// ...args,
		];
		const message = args.shift();
		if (message && typeof message === 'string') {
			output.push(chalk[color](message));
		} else {
			output.push(message);
		}

		output.push(...args);

		// Allow interceptor to grab stuff before hitting console
		if (this.onLog) {
			const success = this.onLog(level, ...args, {
				__loggerMeta: {
					output,
				},
			});
			if (success) {
				return null;
			}
		}

		// For now, just dump to console
		return safeLog(method, ...output);
	}

	static logMultiline(level, string) {
		const lines = string.split('\n');

		// Using .forEach breaks the stackTrace, so use plain loop
		for (let i = 0; i < lines.length; i++) {
			this.log(level, lines[i]);
		}
	}

	static logLevelMeta(levelInput) {
		const {
			type: level,
			customPrefix,
			codeLocationOverride,
			customContextProps,
			customLogger,
		} = levelInput.type ? levelInput : { type: levelInput };

		const { Info, Debug, Warn, Error } = LogLevel;

		const method =
			{
				[Error]: 'error',
				[Warn]: 'warn',
				[Info]: 'log',
				[Debug]: 'debug',
			}[level] || 'log';

		const color =
			{
				[Error]: 'red',
				[Warn]: 'yellow',
				[Info]: 'green',
				[Debug]: 'cyan',
			}[level] || 'green';

		return {
			level,
			method,
			color,
			customPrefix,
			codeLocationOverride,
			customContextProps,
			customLogger,
		};
	}

	static error(...args) {
		this.log(LogLevel.Error, ...args);
	}

	static warn(...args) {
		this.log(LogLevel.Warn, ...args);
	}

	static info(...args) {
		this.log(LogLevel.Info, ...args);
	}

	static debug(...args) {
		this.log(LogLevel.Debug, ...args);
	}

	static e(...args) {
		this.log(LogLevel.Error, ...args);
	}

	static w(...args) {
		this.log(LogLevel.Warn, ...args);
	}

	static i(...args) {
		this.log(LogLevel.Info, ...args);
	}

	static d(...args) {
		this.log(LogLevel.Debug, ...args);
	}

	static getPrefixedCustomLogger(prefix, customContextProps) {
		const customPrefix = prefix;
		// const wrap = (type) => (message, ...args) => {
		// 	const string =
		// 		message instanceof Error
		// 			? `${message.message}\n${message.stack}`
		// 			: message;

		// 	return Logger.log(type, `${prefix} ${string}`, ...args);
		// };

		const fakeAuditLog = (actionId, action, comments /* inputProps = {} */) =>
			Logger.warn(`[NO AUDIT LOGGER]`, action, comments, {
				noAuditLogger: true,
			});

		const {
			auditLog,
			authorization,
			customContextProps: currentLoggerCustomContextProps,
		} = this;

		const customLogger = {};

		const wrap =
			(type) =>
			(...args) => {
				return Logger.log(
					{
						type,
						customPrefix,
						customContextProps: customLogger.customContextProps,
						customLogger,
					},
					...args,
				);
			};

		Object.assign(customLogger, {
			log: (level, ...data) => {
				const logLevel = level;
				if (logLevel.type) {
					// Merge with props from customLogger
					logLevel.customContextProps = {
						...customLogger.customContextProps,
						...logLevel.customContextProps,
					};

					if (!logLevel.customPrefix) {
						logLevel.customPrefix = customPrefix;
					}

					if (!logLevel.customLogger) {
						logLevel.customLogger = customLogger;
					}
				}

				return Logger.log(logLevel, ...data);
			},
			error: wrap(LogLevel.Error),
			warn: wrap(LogLevel.Warn),
			info: wrap(LogLevel.Info),
			debug: wrap(LogLevel.Debug),
			customPrefix,
			// Can set persistent props here...
			customContextProps: {
				...currentLoggerCustomContextProps,
				...customContextProps,
			},
			// For app-specific props for AuditLog
			auditLog: auditLog || fakeAuditLog,
			authorization,

			getPrefixedCustomLogger: (prefixSuffix, extraCustomProps) => {
				return Logger.getPrefixedCustomLogger(
					`${customPrefix}${prefixSuffix}`,
					{ ...customContextProps, ...extraCustomProps },
				);
			},
		});

		// This keeps us from having to do this on every call in the app...
		if (auditLog) {
			auditLog.logger = customLogger;
		}

		return customLogger;
	}

	static alert(label, ...args) {
		if (this.onAlert) {
			if (this.onAlert(label, ...args)) {
				return;
			}
		}

		Logger.warn(`Unhandled ALERT: ${label}`, ...args);
	}

	// This covers situations when developing where we want to run code without attaching an audit logger,
	// and it keeps code from crashing with "logger.auditLog is not defined"
	static auditLog(actionId, action, comments /* inputProps = {} */) {
		Logger.warn(
			`No Audit Logger configured on logger instance, logging straight to logs`,
		);
		// Not logging inputProps because they likely are complex this is just a backup
		Logger.info(`[${actionId}] ${action}: ${comments}`);
	}
}

export default Logger;

Logger.LogLevel = LogLevel;

// Passthru filter for use in scripts/simulations to throw errors instead of just logging
export const throwOnErrorFilter = (
	levelInput,
	location,
	pid,
	message,
	// ...args
) => {
	const {
		level,
		// method,
		// color,
		customPrefix,
		// location already overriden by the time the filter gets it
		// codeLocationOverride,
		// customContextProps,
		// customLogger,
	} = Logger.logLevelMeta(levelInput);

	// Make a textual log string for both the TODO log and the api.log
	const logString = [
		pid, // include pid to distinguish easily between restarts
		new Date().toISOString(),
		customPrefix || '',
		`[${level}]`,
		location,
		message instanceof Error ? `${message.message}\n${message.stack}` : message,
		'\n',
	].join(' ');

	if (level === Logger.LogLevel.Error) {
		throw new Error(logString);
	}

	return true;
};
