import AppConfig from 'shared/config';
import PulsingCoverLoader from 'shared/components/PulsingCoverLoader';

export default function BootAnim() {
	// Every time the native app boots and redirects to a remote host
	// does NOT mean that the remote host was updated. It's possible
	// the remote host is running the same exact software as the last
	// time the native app redirected. Therefore, I felt it's "bad UX"
	// to show "downloading updated software" if the software, in fact,
	// was not actually updated.
	//
	// We use the git hash to detect if the software running on this
	// host is the same as the last time the device visited or
	// if this hash is different. If different hashes, show
	// the "downloading updates" message, otherwise, show some generic welcome.
	const currentBuildHash = AppConfig.gitVersion;
	const lastExecutedHashKey = '@rubber/last-executed-hash';
	const lastExecutedHash = window.localStorage.getItem(
		lastExecutedHashKey,
	);
	const message =
		lastExecutedHash === currentBuildHash
			? 'Starting your luxury experience'
			: 'Downloading updated software';

	// Store THIS hash for the next time the device goes thru this block
	window.localStorage.setItem(lastExecutedHashKey, currentBuildHash);

	return (
		<PulsingCoverLoader>
			<div
				style={{
					background: 'rgba(226,172,215,0.75)',
					color: '#6A2A5B',
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '.85rem',
					fontFamily: "'Roboto', sans-serif",
					fontWeight: 500,
					letterSpacing: '1px',
					padding: '.5rem',
					position: 'fixed',
					zIndex: '100001',
					borderRadius: '100%',
					width: '8rem',
					height: '8rem',
					lineHeight: '1.2',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			>
				{message}
			</div>
		</PulsingCoverLoader>
	);
}
