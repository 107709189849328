import Logger, { isNode } from './IsomorphicLogger';
import { defer } from './defer';

// Timers for use with later
const laterTimers = {};

// For awaiting all promises in scripts
let laterWaitList = [];

// Exporting this internal trap so we can use the trapping logic apart from the later delay,
// for example in cron jobs
export const trapAsyncErrors = async (
	callbackOrPromise,
	{ laterStack = new Error('stack trace please').stack, logger = Logger } = {},
) => {
	try {
		const promise =
			typeof callbackOrPromise === 'function'
				? callbackOrPromise()
				: callbackOrPromise;

		if (promise && typeof promise.catch === 'function') {
			await promise.catch((ex) => {
				const context = {
					message: ex.message,
					errorStack: ex.stack,
					laterStack,
					originalException: ex,
				};

				logger.error(`Error in promise given to later:`, context);
				if (!isNode) {
					logger.error(ex);
				}

				// .alert is only relevant on the server
				if (isNode) {
					Logger.alert(`AsyncErrorTrap-PromiseRejected`, context);
				}
			});
		}
	} catch (ex) {
		const context = {
			message: ex.message,
			errorStack: ex.stack,
			laterStack,
			originalException: ex,
		};

		logger.error(`Error caught in trapAsyncErrors():`, context);

		// .alert is only relevant on the server
		if (isNode) {
			Logger.alert(`AsyncErrorTrap-CaughtException`, context);
		}
	}
};

/**
 * Simple utility to move a given promise out of the event loop.
 * Useful for when you don't care about the return value, but still want to catch errors. This will
 * .catch() errors and log using the given (or default) Logger.
 *
 * @param {function|Promise} callbackOrPromise Function to call or promise to await. Function is assumed async, and as such, assumed to return a promise which will be awaited.
 * @param {Object} options (optional)
 * @param {Logger} options.logger (default: `Logger`) Logger instance to use for error logging
 * @param {number} options.delay (default: `0`) Delay to use before awaiting the promise/calling the callback. Delay of 0 just moves it to the next event loop.
 * @param {boolean} options.immediate (default: `false`) If true, does NOT delay till next tick, just wraps the `callbackOrPromise` with error catching and returns an `await`-able value
 */
export const later = (callbackOrPromise, options) => {
	const { stack: laterStack } = new Error('later called from...');

	const finished = defer();

	let {
		logger = Logger,
		delay = 0,
		key,
		immediate = false,
	} = !Number.isNaN(options) ? { delay: options } : options || {};

	if (laterTimers[key]) {
		clearTimeout(laterTimers[key]);
	}

	if (immediate) {
		return trapAsyncErrors(callbackOrPromise, { laterStack, logger });
	}

	setTimeout(async () => {
		await trapAsyncErrors(callbackOrPromise, { laterStack, logger });
		finished.resolve();

		laterWaitList = laterWaitList.filter((x) => x !== finished);
	}, delay || 0);

	if (key) {
		laterTimers[key] = finished;
	}

	laterWaitList.push(finished);

	return Promise.resolve(finished);
};

export const waitForAllLater = () => Promise.all(laterWaitList);

/**
 * Returns a function that wraps later() and injects the logger
 * @param {Logger} logger
 * @returns {Function} later() with the given logger injected into the props
 */
export const getLaterWithLogger = (logger) => (callback, options) =>
	later(callback, { ...options, logger });
