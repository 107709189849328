// Utility wrapper around fetch to force timeout after X ms
export default function fetchWithTimeout(
	url = '',
	{ timeout, ...options } = {
		timeout: 2000,
	},
) {
	// May be null if AbortController does not exist, which fetch will just ignore then
	let signal;

	// Only setup aborting if AbortController defined on window
	const { AbortController } = window;
	if (AbortController) {
		// Create the controller and get the signal to patch to fetch, below
		const controller = new AbortController();

		// eslint-disable-next-line prefer-destructuring
		signal = controller.signal;

		// According to https://developers.google.com/web/updates/2017/09/abortable-fetch:
		// Note: It's ok to call .abort() after the fetch has already completed, fetch simply ignores it.
		setTimeout(() => controller.abort(), timeout || 5000);
	}

	// Pass thru all other options to fetch
	return fetch(url, { ...options, signal });
}
