/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import LogRocket from 'shared/services/LogRocket';
import setupLogRocketReact from 'logrocket-react';
import AppConfig from 'shared/config-public';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import appUpdateLoader from './appUpdateLoader';
import './index.css';
// import App from './App.js';

if (AppConfig.buildEnv !== 'dev') {
	// Don't log in dev because it messes up console line numbers
	LogRocket.init(`xmpbov/vaya-${AppConfig.buildEnv}`);
	setupLogRocketReact(LogRocket);
}

// Fix for 2021 Bitly Pershing Link: https://bitly.com/pershingxvaya+
if (`${window.location.href}`.includes(':text=9NH7Z,join')) {
	window.localStorage.setItem('@rubber/saved-referral-code', '9NH7Z');
	setTimeout(() => {
		window.location.href = 'https://vayadriving.com/?pershingxvaya';
	}, 500);
}

// This actually boots App.js (or redirects to remote server)
// depending on heuristics internally
if (AppConfig.buildEnv !== 'dev') {
	appUpdateLoader();
} else {
	// import App from './App.js';
	import('./App.js').then(({ default: App }) =>
		ReactDOM.render(<App />, document.getElementById('root')),
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
