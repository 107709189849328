/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

// Future Developer Note:
// Any imports you load here go into the default bundle.
// In other words, you'll make the time bundles boot longer and larger.
// The goal with this file is to keep the imports to a minimum
// and NOT import any app-specific code here, because we may redirect
// and not boot the app. We use a dynamic import() below to actually
// load the app - this lets webpack tree-shake and make a separate
// physical bundle for this file and for the app bundle, so it can
// just load this file without loading the app, saving time and bandwidth.
import React from 'react';
import ReactDOM from 'react-dom';

// Need capacitor to detect what type of platform (web/ios/android) we're on
import { Capacitor } from '@capacitor/core';

// Utilities and app config
import AppConfig from 'shared/config-public';
import { later } from 'shared/utils/later';
import fetchWithTimeout from 'shared/utils/fetchWithTimeout';

// This is the ONLY app component we import and we "know"
// it has limited child imports so it is light and can render
// quickly while the rest of the app boots.
import BootAnim from './components/BootAnim';

// Env selection storage
const USER_UPDATE_ENV_KEY = '@rubber/update-user-env';

// // Guard key for redirects from native > remote
// // Not used really, keeping it in case we use it later
// const updateKey = '_updatedAt';

// Key for remote > native to update local storage on other origin
const prefKey = '_envSelect';

// Remove '/#' from the end of our frontend URLs so we can compose them with '/version.json' below
const cleanFrontendUrl = (url) => `${url}`.replace(/\/#?$/, '');

// Dedicated redirect utility so we can override for testing
// Defined as 'let' for mocking.
let redirectToUrl = (url) => {
	window.location.href = url;
};

// Dedicated render function so we can override to mock for testing
let renderComponentInDom = (Component) => {
	ReactDOM.render(<Component />, document.getElementById('root'));
};

// Dedicated accessor for mocking if needed
let getCurrentPlatform = () => Capacitor.getPlatform();

// Dedicated function for mocking if needed
let fetchWithTimeoutUtility = fetchWithTimeout;

/**
 * Utility for unit testing to override the redirect
 * @param {function} fn Function to use for redirectToUrl
 */
export const mockRedirectUrl = (fn) => {
	redirectToUrl = fn;
};

/**
 * Utility for unit testing to override render function
 * @param {function} fn Function to use in place of ReactDOM.render
 */
export const mockRenderComponent = (fn) => {
	renderComponentInDom = fn;
};

/**
 * Utility for unit testing to override getCurrentPlatform
 * @param {function} fn Function to use in place of Capacitor.getPlatform
 */
export const mockCurrentPlatformAccessor = (fn) => {
	getCurrentPlatform = fn;
};

/**
 * Utility for unit testing to override fetchWithTimeoutUtility
 * @param {function} fn Function to use in place of fetchWithTimeout
 */
export const mockFetchUtility = (fn) => {
	fetchWithTimeoutUtility = fn;
};

// If set, uses this URL for decisions instead of window.location.href
let currentUrlForTesting;

/**
 * Overrides `window.location.href` internally and uses the specified string
 * instead for tests.
 * @param {string} url URL to use for tests
 */
export const setCurrentUrlForTesting = (url) => {
	currentUrlForTesting = url;
};

// These should exist in AppConfig.envRootUrls, otherwise likely will fail
export const VALID_ENV_LIST = ['dev', 'staging', 'prod'];

/**
 * Generates the URL for use in calling back to the native bundle (ex. via an IFRAME).
 * If `redirect` is true, then instead it will redirect the current `window` to the URL.
 *
 * @param {string} params.env [default: 'prod'] Env selection to use, one of `VALID_ENV_LIST`
 * @param {boolean} params.redirect [default: `false`] If true, will redirect to the generated URL. If false, just returns the URL composed (e.g. for use in an IFRAME)
 * @returns {string} URL composed for calling back to native bundle
 */
export function updateEnvPref({ env = 'prod', redirect = false }) {
	if (!VALID_ENV_LIST.includes(env)) {
		console.error(
			`appUpdateLoader: Cannot updateEnvPref to '${env}' because it is not one of [${VALID_ENV_LIST}]`,
		);
	}

	// Ignore redirect if on the web, since iframe would be useless,
	// instead, just redirect this frame to the new frontend
	if (getCurrentPlatform() === 'web') {
		redirectToUrl(
			`${
				AppConfig.envRootUrls[env].frontend
			}?_updatedAt=${Date.now()}`,
		);
	}

	const nativeBundleUrl = `${
		getCurrentPlatform() === 'ios' ? 'capacitor' : 'http'
	}://localhost/`;

	const prefUrl = `${nativeBundleUrl}?_envSelect=${env}&_boot=${
		redirect ? 'true' : 'false'
	}`;

	if (!redirect) {
		return prefUrl;
	}

	// Load the native bundle and flow thru the update again to the new env
	redirectToUrl(prefUrl);

	// Return to keep linter happy, even tho redirecting
	return prefUrl;
}

// For technician usage - should expose this in the UI for
// admins or QA to switch envs running in the app
window.updateEnvPref = updateEnvPref;

/**
 * This is the main "exit" point for the appUpdateLoader.js, it returns
 * control of the current web view (and DOM) to our App.js code.
 *
 * Think of the flow as going:
 * ```
 * appUpdateLoader > redirectOrBoot > bootCurrentBundle
 * ```
 *
 * Where `redirectOrBoot`, if not redirecting, calls `bootCurrentBundle`
 * at a couple different exit points to start booting the actual app.
 *
 * This takes advantage of ES6's dynamic `import()`s and webpacks
 * tree-shaking/bundle-splitting to delay ANY parsing or execution of
 * the rest of the app until we call this function.
 *
 * This ensures the update code (this file) has complete control over the
 * DOM and does not have to worry about setting some flag to stop
 * the app from rendering while still updating.
 *
 * Using the import() also allows us to instead render a lightweight
 * `BootAnim` while waiting on heuristic decisions
 */
async function bootCurrentBundle() {
	const startTime = Date.now();
	console.log(`appUpdateLoader: Booting local bundle from App.js..`);

	let error;
	const { default: App, ...props } =
		(await import('./App').catch((ex) => {
			error = ex;
		})) || {};

	if (!App) {
		// Basically an unrecoverable error
		console.error(
			`Unrecoverable Error importing App, the application will fail:`,
			{
				message: error && error.message,
				stack: error && error.stack,
				props,
			},
		);
		return;
	}

	const endTime = Date.now();
	const loadTime = endTime - startTime;
	console.log(
		`appUpdateLoader: App.js loaded, mounting on DOM and returning control to the app. (Bundle loaded in ${loadTime} ms)`,
	);

	// Mount <App> in the DOM and the app will continue it's normal boot from there
	renderComponentInDom(App);
}

/**
 * Used internally by redirectOrBoot to extract the _env key
 * from the URL and store it in this origin's localStorage for later
 * usage.
 */
function handlePrefUpdate(currentUrl) {
	try {
		// Parse the query string to get the value of _envSelect for storage
		const params = new URLSearchParams(new URL(currentUrl).search);
		const prefValue = params.get(prefKey);

		// Store for next time local boots
		window.localStorage.setItem(USER_UPDATE_ENV_KEY, prefKey);

		console.log(
			`appUpdateLoader: Received ${prefKey} in URL, changed localStorage key to env:`,
			prefValue,
		);

		// Unload the app since this is designed to be called from an IFRAME
		// and hidden, used basically like an RPC back to the native bundle
		redirectToUrl('about:blank');
	} catch (ex) {
		// If currentUrl has parsing errors, `new URL(...)` WILL throw an exception
		// so catch it here so we don't leave dangling exceptions and so
		// we can properly log the context (currentUrl) that likely caused the exception
		console.error(
			`appUpdateLoader: Cannot update user env preference due to error`,
			{
				currentUrl,
				message: ex.message,
				stack: ex.stack,
				originalException: ex,
			},
		);
	}
}

/**
 * This is the core update heuristic, it primarily deals with
 * determining the proper remote URL to use and then determines if the
 * device has network access and if so, redirects the web view to that
 * new remote URL. There's far more nuance to it, but you can read
 * the code yourself - that's the big picture intent, anyway.
 */
async function redirectOrBoot() {
	// Just stringify for ease of use below
	const currentUrl = `${currentUrlForTesting || window.location.href}`;

	// Grab configs from app.config.js
	const {
		buildEnv: defaultRemoteEnv,
		frontendTunnelRoot,
		backendTunnelHost,
		envRootUrls,
	} = AppConfig;

	// If prefKey present, the remote URL has asked our native bundle
	// to update the localStorage env preference, so handle that and don't boot
	const prefUpdate = currentUrl.includes(prefKey);
	if (prefUpdate) {
		// Apply the pref key to local storage in the native web view
		handlePrefUpdate(currentUrl);

		// _boot should not be present or be false if being called from an iframe
		// If true, then we'll fall thru and allow the native bundle to boot here.
		if (!currentUrl.includes('_boot=true')) {
			return;
		}
	}

	// This allows (future) UI work to let users (admins? QA?) choose an env to update with
	const userRemoteEnvSelection =
		window.localStorage.getItem(USER_UPDATE_ENV_KEY) ||
		defaultRemoteEnv;

	// Get the env-specific bundle URLs.
	// Note that this is present even in prod bundles so we can switch back
	// to dev/staging from a prod app if desired (if allowed...)
	let rootUrlSet = envRootUrls[userRemoteEnvSelection];
	if (!rootUrlSet) {
		console.error(
			`appUpdateLoader: Invalid env selection '${userRemoteEnvSelection}' - no envRootUrl set defined in app.config.js for that key, defaulting to prod URLs`,
		);
		rootUrlSet = envRootUrls.prod;
	}

	// This is the location on the internet of the remote bundle
	// we will redirect to if our versionUrl fetch succeeds
	let remoteBundleUrl;
	if (userRemoteEnvSelection === 'dev') {
		remoteBundleUrl = cleanFrontendUrl(frontendTunnelRoot);
	} else {
		remoteBundleUrl = cleanFrontendUrl(rootUrlSet.frontend);
	}

	// versionUrl is used to check online status - if we succeed in fetching
	// this URL in a reasonable amount of time, we assume we're online
	// and that the remote bundle is (likely) newer, so we can redirect
	// to remote bundle without booting/mounting the local bundle
	let versionUrl;
	if (userRemoteEnvSelection === 'dev') {
		// On dev, COORS on Ngrok FE prevent us front properly requesting version.json,
		// so use this purpose-built BE route to effect the same response
		versionUrl = `https://${backendTunnelHost}/api/v1/version`;
	} else {
		// Remote bundle URLs will already include 'https://'
		versionUrl = `${remoteBundleUrl}/version.json`;
	}

	// Stop from looping - if currentUrl is already pointed at remoteBundleUrl,
	// then boot the current bundle
	if (
		currentUrl.includes(remoteBundleUrl) ||
		// React dev server for testing, because in dev, remoteBundleUrl is tunnel (ngrok),
		// so it wouldn't match localhost (.dev.frontend)
		currentUrl.includes(envRootUrls.dev.frontend)
	) {
		console.log(
			`appUpdateLoader: Running on remote-ish URL, not checking further`,
			{
				currentUrl,
				remoteBundleUrl,
				envRootUrls,
				userRemoteEnvSelection,
			},
		);
		bootCurrentBundle();
		return;
	}

	console.log(`appUpdateLoader: resolved urls:`, {
		versionUrl,
		remoteBundleUrl,
		userRemoteEnvSelection,
		envRootUrls,
	});

	if (!versionUrl) {
		// This should never happen, but guard against weird errors if versionUrl
		// is falsey by showing our own error so we have more context for debugging if needed
		console.error(
			`AppUpdateUrl: no versionUrl to check for online status, assuming NOT online and booting local bundle`,
		);
		bootCurrentBundle();
		return;
	}

	// If we have a URL, means we have a valid env selection
	// and we are NOT running on a remote URL, so check to see if we're online
	// so we can redirect
	const versionUrlWithCacheBust = `${versionUrl}?_=${Date.now()}`;

	// Date is present to do cache-busting on load
	// Key '_updatedAt' is not presently used at this moment, other than cache-bust-holder
	// Key '_bundleRoot' used to allow remote host to know what URL to iframe for callback with _envSelect (above) - TBD if we actually need _bundleRoot or if it's just dead weight
	const redirectUrl = `${remoteBundleUrl}?_updatedAt=${Date.now()}&_bundleRoot=${
		window.location.protocol
	}//${window.location.host}`;

	// For debugging...
	const ignoreUpdatesFlag = `${currentUrl}`.includes('_ignoreUpdates');

	// This timeout is indeed possible on slow networks or busy booting.
	// If we fail here, assume offline and don't redirect to remote
	let versionFetchFailed = false;

	// Not doing anything with result - in future, could check versions? tbd
	await fetchWithTimeoutUtility(versionUrlWithCacheBust)
		.then((data) => data.json())
		.catch((ex) => {
			versionFetchFailed = ex;
		});

	// If error (or timeout), log and just boot the local bundle
	if (versionFetchFailed) {
		// Failure fetching, might be offline, just return and let bundle boot
		console.error(
			`appUpdateLoader: Error fetching ${versionUrlWithCacheBust} (${userRemoteEnvSelection}):`,
			{
				message: versionFetchFailed.message,
				stack: versionFetchFailed.stack,
				originalException: versionFetchFailed,
			},
		);

		bootCurrentBundle();
		return;
	}

	// We must have set this flag for debugging, so don't actually redirect so we
	// can inspect console output
	if (ignoreUpdatesFlag) {
		console.log(
			`index.updates: found _ignoreUpdates in the URL, stopping and running app`,
			{
				currentUrl,
				userRemoteEnvSelection,
				remoteBundleUrl,
				envRootUrls,
				redirectUrl,
				ignoreUpdatesFlag,
			},
		);

		bootCurrentBundle();
		return;
	}

	console.log(
		`appUpdateLoader: Online and appear to not be up-to-date, so going to redirect below...`,
		{
			currentUrl,
			remoteBundleUrl,
			envRootUrls,
			redirectUrl,
			ignoreUpdatesFlag,
		},
	);

	// Exit the app and reload.
	redirectToUrl(redirectUrl);
}

/**
 * Primary entry point for the application.
 * This should be called in index.js in place of the traditional `ReactDOM.render` call,
 * since we do the `.render` call internally
 *
 * NOTE: Also exported from this file is `mockRedirectUrl`, `mockRenderComponent`,
 * `mockCurrentPlatformAccessor`, and `mockFetchUtility`, which can be used to add
 * hooks to this file for unit testing.
 */
export default async function appUpdateLoader(disableLater) {
	// Render our boot anim until we decide what to do (redirect or bootCurrentBundle)
	renderComponentInDom(BootAnim);

	// Move out of event loop so boot anim can render before we do other things
	const internalUpdate = async () => {
		if (getCurrentPlatform() === 'web') {
			// If not running in a native context, nothing to do,
			// so just boot current bundle. Await to ensure
			// any thrown errors caught and logged by later()
			await bootCurrentBundle();
			return;
		}

		// Execute our heuristic code. Await to ensure
		// any thrown errors caught and logged by later()
		await redirectOrBoot();
	};

	if (disableLater) {
		await internalUpdate();
		return;
	}

	later(internalUpdate);
}
